import { AbstractService } from '@/service/abstract'
import { QtDns } from '@/service/qtDns'
import { QtDnsZones } from '@/service/qtDnsZones'
import { QtDomain } from '@/service/qtDomain'

export const serviceFactory = (
  serviceName: string,
  t: any,
  d: any
): AbstractService => {
  switch (serviceName) {
    case 'qt-dns':
      return new QtDns(t, d)
    case 'qt-dns-zones':
      return new QtDnsZones(t, d)
    case 'qt-domain':
      return new QtDomain(t, d)
    default:
      throw new Error('Unkown Error')
  }
}
