
import * as Sentry from '@sentry/browser'
import { get as getServices, Service } from '@/api/customer/service/list'
import { post as postServiceCancel } from '@/api/customer/service/cancel'
import { defineComponent, ref, onMounted, reactive } from 'vue'
import { useTranslation } from '@/locales'
import { serviceFactory } from '@/service'

export default defineComponent({
  setup() {
    const openCancelServiceDialog = ref(false)
    const items = reactive<{ services: Service[] }>({ services: [] })
    const services = ref(items.services)
    const selected = ref('')
    const notFoundService = ref('')
    const redirectToOrderQtdns = `/ja/service/order/qt-dns`
    const { t, d } = useTranslation()

    const getUrl = (name: string) => {
      switch (name) {
        case 'QUALITIA DNS':
          return process.env.VUE_APP_QUALITIA_DNS_URL
        default:
          return undefined
      }
    }

    const redirectTo = (url?: string) => {
      if (url != null) {
        location.replace(url)
      }
    }

    const updateServices = () =>
      getServices()
        .then((res) => {
          console.log(res)
          services.value = res.map(
            ({ expireDate, name, serviceName, identifier, ...rest }) => {
              const serviceManager = serviceFactory(serviceName, t, d)
              return {
                url: getUrl(name),
                name: serviceManager.getServiceNameForList(name, identifier),

                expireDate:
                  expireDate != null && expireDate.length > 0
                    ? d(expireDate, 'ymd')
                    : '',
                serviceName,
                ...rest,
              }
            }
          )
          console.log(services)
        })
        .catch(() => {
          services.value = []
          notFoundService.value = t('There is not available services now')
        })

    onMounted(() => updateServices())

    const openDialog = (name: string) => {
      selected.value = name
      openCancelServiceDialog.value = true
    }

    const closeDialog = () => {
      selected.value = ''
      openCancelServiceDialog.value = false
    }

    const cancel = () => {
      postServiceCancel(selected.value)
        .then(() => updateServices())
        .catch(() => (services.value = []))
      openCancelServiceDialog.value = false
    }

    return {
      openCancelServiceDialog,
      services,
      redirectToOrderQtdns,
      notFoundService,
      redirectTo,
      t,
      d,
      cancel,
      openDialog,
      closeDialog,
    }
  },
})
