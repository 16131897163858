<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ t('Service Name') }}
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ t('LimitDate') }}
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ t('Amount') }}
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                ></th>
                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only"></span>
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(item, index) in services" :key="index">
                <td class="px-6 py-4 whitespace-nowrap">
                  <div
                    :class="[
                      item.url != null
                        ? 'cursor-pointer text-blue-500 hover:text-blue-300 font-medium'
                        : 'cursor-default text-gray-900',
                      'text-sm font-medium  text-left',
                    ]"
                    @click="redirectTo(item.url)"
                  >
                    {{ item.name }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left"
                >
                  {{ item.expireDate }}
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left"
                >
                  {{ item.amount }}
                </td>
                <template
                  v-if="
                    item.serviceName === 'qt-dns' ||
                    item.serviceName === 'qt-domain'
                  "
                >
                  <td class="px-6 py-4 whitespace-nowrap text-left">
                    <span
                      v-if="item.status === 'trial'"
                      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                    >
                      {{ t('Trial') }}
                    </span>

                    <span
                      v-else-if="item.status === 'processing'"
                      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                    >
                      {{ t('Processing') }}
                    </span>
                    <span
                      v-else-if="item.status === 'canceled'"
                      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-purple-800"
                    >
                      {{ t('canceled') }}
                    </span>
                    <span
                      v-else-if="item.cancel === true"
                      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
                    >
                      {{ t('Not updating subscription') }}
                    </span>
                    <span
                      v-else
                      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800"
                    >
                      {{ t('Subscription') }}
                    </span>
                  </td>

                  <td
                    class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                  >
                    <a
                      v-if="item.status === 'trial'"
                      :href="redirectToOrderQtdns"
                      class="bg-black py-1 px-3 text-white text-sm hover:text-gray-300 cursor-pointer"
                      >{{ t('Apply to production') }}</a
                    >
                    <button
                      v-else-if="
                        item.serviceName === 'qt-dns' &&
                        item.status === 'production' &&
                        item.cancel !== true
                      "
                      class="bg-red-500 text-white px-2 py-1 cursor-pointer rounded-md focus:outline-none"
                      @click="openDialog(item.serviceName)"
                    >
                      {{ t('Cancel subscription') }}
                    </button>
                  </td>
                </template>
                <template v-else>
                  <td class="px-6 py-4 whitespace-nowrap text-left" />

                  <td
                    class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                  />
                </template>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="services.length === 0" class="px-2 py-2 font-bold">
          {{ notFoundService }}
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="openCancelServiceDialog"
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      >
        <div class="bg-red-500 text-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                id="modal-title"
                class="text-lg leading-6 font-medium text-white border-b-2"
              >
                {{ t('Cancel subscription') }}
              </h3>
              <p class="text-base text-white mt-2">
                {{
                  t(
                    'You are going to cancel this service. We can not provide this service for you after cancelletion. Are you are?'
                  )
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            class="mt-3 w-32 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            @click="cancel"
          >
            {{ t('Yes') }}
          </button>
          <button
            type="button"
            class="mt-3 w-32 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            @click="closeDialog"
          >
            {{ t('No') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import * as Sentry from '@sentry/browser'
import { get as getServices, Service } from '@/api/customer/service/list'
import { post as postServiceCancel } from '@/api/customer/service/cancel'
import { defineComponent, ref, onMounted, reactive } from 'vue'
import { useTranslation } from '@/locales'
import { serviceFactory } from '@/service'

export default defineComponent({
  setup() {
    const openCancelServiceDialog = ref(false)
    const items = reactive<{ services: Service[] }>({ services: [] })
    const services = ref(items.services)
    const selected = ref('')
    const notFoundService = ref('')
    const redirectToOrderQtdns = `/ja/service/order/qt-dns`
    const { t, d } = useTranslation()

    const getUrl = (name: string) => {
      switch (name) {
        case 'QUALITIA DNS':
          return process.env.VUE_APP_QUALITIA_DNS_URL
        default:
          return undefined
      }
    }

    const redirectTo = (url?: string) => {
      if (url != null) {
        location.replace(url)
      }
    }

    const updateServices = () =>
      getServices()
        .then((res) => {
          console.log(res)
          services.value = res.map(
            ({ expireDate, name, serviceName, identifier, ...rest }) => {
              const serviceManager = serviceFactory(serviceName, t, d)
              return {
                url: getUrl(name),
                name: serviceManager.getServiceNameForList(name, identifier),

                expireDate:
                  expireDate != null && expireDate.length > 0
                    ? d(expireDate, 'ymd')
                    : '',
                serviceName,
                ...rest,
              }
            }
          )
          console.log(services)
        })
        .catch(() => {
          services.value = []
          notFoundService.value = t('There is not available services now')
        })

    onMounted(() => updateServices())

    const openDialog = (name: string) => {
      selected.value = name
      openCancelServiceDialog.value = true
    }

    const closeDialog = () => {
      selected.value = ''
      openCancelServiceDialog.value = false
    }

    const cancel = () => {
      postServiceCancel(selected.value)
        .then(() => updateServices())
        .catch(() => (services.value = []))
      openCancelServiceDialog.value = false
    }

    return {
      openCancelServiceDialog,
      services,
      redirectToOrderQtdns,
      notFoundService,
      redirectTo,
      t,
      d,
      cancel,
      openDialog,
      closeDialog,
    }
  },
})
</script>
