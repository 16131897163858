import { AbstractService, Product } from '@/service/abstract'

export class QtDns extends AbstractService {
  constructor(t: any, d: any) {
    super(t, d)
  }

  public getServiceName(
    amount: number,
    startDate: string,
    endDate: string,
    paymentFrequency: string,
    domainName?: string
  ) {
    if (paymentFrequency === 'monthly') {
      return this.t('QUALITIA DNS Monthly for {month}', {
        month: this.d(new Date(endDate), 'm'),
      })
    } else if (paymentFrequency === 'year') {
      return this.t('QUALITIA DNS Year from {start} to {end}', {
        start: this.d(new Date(startDate), 'terms'),
        end: this.d(new Date(endDate), 'terms'),
      })
    }
    return ''
  }

  public getProduct() {
    return {
      name: this.t('QUALITIA DNS'),
      description: this.t('QUALITIA DNS product description'),
      url: 'service/order/qt-dns',
      img: require('@/assets/img/dns.jpg'), // TODO: implement image file
    } as Product
  }
}
