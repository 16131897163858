import { errorHandler } from '@/api/utils'
import { http } from '@/plugins/ky'

export type Service = {
  name: string
  serviceName: string
  startDate?: string
  expireDate?: string
  status: string
  amount: number
  subscription?: boolean
  cancel?: boolean
  url?: string
  identifier?: any
}

export const get = () =>
  new Promise<Service[]>((resolve, reject) =>
    http
      .get('customer/service/list')
      .then(async (r: any) => {
        const result = await r.json()

        resolve(result)
      })
      .catch(async ({ response, message }: any) => {
        await errorHandler(response, message)

        reject([])
      })
  )
