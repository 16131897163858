import { AbstractService, Product } from '@/service/abstract'

export class QtDomain extends AbstractService {
  constructor(t: any, d: any) {
    super(t, d)
  }

  getServiceNameForList(name: string, identifier?: any) {
    if (identifier == null) {
      throw new Error('Not found identifier')
    }
    return this.t('General JP {domainName}', { domainName: identifier })
  }

  public getServiceName(
    amount: number,
    startDate: string,
    endDate: string,
    paymentFrequency: string,
    domainName?: string
  ) {
    if (domainName == null) {
      throw new Error('DOMAIN name')
    }

    return this.t('General JP {domainName}', { domainName })
  }

  public getProduct() {
    return {
      name: this.t('DOMAIN'),
      description: this.t('DOMAIN product description'),
      url: 'service/order/qt-domain',
      img: '', // TODO: implement image file
    } as Product
  }
}
