export type Product = {
  name: string
  description: string
  url: string
  img: string
}

export abstract class AbstractService {
  protected t: any // TODO: should be generics
  protected d: any // TODO: should be generics

  constructor(t: any, d: any) {
    this.t = t
    this.d = d
  }

  getServiceNameForList(name: string, identifier?: any) {
    return name
  }

  abstract getServiceName(
    amount: number,
    startDate: string,
    endDate: string,
    paymentFrequency: string,
    domainName?: string
  ): string

  abstract getProduct(): Product
}
