import { errorHandler } from '@/api/utils'
import { http } from '@/plugins/ky'

export const post = (serviceCategory: string) =>
  new Promise<void>((resolve, reject) =>
    http
      .post('customer/service/cancel', {
        json: { serviceCategory },
      })
      .then(() => resolve())
      .catch(async ({ response, message }: any) => {
        await errorHandler(response, message)
        reject()
      })
  )
